import React, { useEffect, useState } from "react";
import PureModal from "src/components/shared/PureModal";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import "./UserVoucherModal.scss";
import { addPocVoucher, addUserVoucher, updateUsage } from "src/api/vouchers";
import { fetchVoucherLabs } from "src/api/lab";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { mapVoucherLabs } from "src/helpers/labs";
import { isEmpty } from "src/utils/common";
import { pushNotification } from "src/components/ui/Notification/index";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomTooltip from "src/components/ui/CustomTooltip";
import copy from "src/assets/images/copy-code.svg";
import { useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { userTypesMap } from "src/constants/users";
import oppIdValidationSchema, { extractOppIdFromUrl } from "src/helpers/validationsSchema/oppIdValidationSchema";
import { useParams } from "react-router-dom";
import { UsageType } from "src/components/Labs/LaunchLab/LaunchLab";

type UserVoucherModalProps = {
  setShowModal: (boolean) => void;
  type?: "poc" | "userVoucher" | null;
  voucherName?: () => string;
  voucherAdded?: () => void;
};

Yup.addMethod(Yup.array, "unique", function (this, errorMsg) {
  return this.test("uniqueTest", errorMsg, function (list) {
    return list.length === new Set(list.map()).size;
  });
});



const newVoucher = {
  name: "",
  lab: "",
  email: "",
  voucherCode: "",
  sendEmail: false,
  opportunityID: "",
  usage: "",
  other: "",
  customerName: ""
};

const pushVoucherCopyNotification = () =>
  pushNotification({
    type: "info",
    message: "Voucher URL Copied",
  });

const UserVoucherModal: React.FunctionComponent<UserVoucherModalProps> = ({
  ...props
}) => {
  const initialValues = newVoucher;

  initialValues.name = (props.voucherName && props.voucherName()) || '';
  const user = useSelector((state: RootState) => state.user);

  const { pocId }: { pocId } = useParams();
  const [usageTab, setUsageTab] = useState<string>("sales");
  const [showUsageType, setShowUsageType] = useState(false);
  const toggleUsageTab = (key?) => setUsageTab(key);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    lab: Yup.string().required(props.type == 'poc' ? 'Hosted POC Lab is required' : "Lab is required"),
    email: Yup.string().email("Email is invalid"),
    // opportunityID: props.type == 'poc' ? Yup.string().notRequired() : oppIdValidationSchema(user.type),
    opportunityID: props.type == 'poc' ? Yup.string().notRequired() : ((user.type === userTypesMap.corporate || user.type === userTypesMap.partner) && showUsageType) && usageTab === 'sales' ? oppIdValidationSchema() : Yup.string().notRequired(),
    other: Yup.string().when("usage", {
      is: "other",
      then: Yup.string().required("Please explain"),
    }),
    customerName: Yup.string().when("usage", {
      is: "Customer Demo - No Active Opportunity",
      then: Yup.string().required("Customer Name is required"),
    }),
  });

  const [voucherCodes, setVoucherCodes] = useState<
    Array<{ name: String; code: String }>
  >([]);
  const [labs, setLabs] = useState<Array<any>>([]);

  const getLabs = async () => {
    const data = await fetchVoucherLabs(props.type);
    setLabs(mapVoucherLabs(data));
  };
  useEffect(() => {
    getLabs();
  }, []);

  const getPayload = (voucher) => {
    // TODO: dynamic payload construction
    let payload = {
      name: voucher.name,
      send_email: voucher.sendEmail,
      lab_assigned: labs.find((val) => val.name === voucher.lab).id,

    };
    if (props.type == 'poc') {
      payload['poc_assigned'] = pocId;
    } else {
      payload['usage_type'] = usageTab.toUpperCase();
      payload['usage'] = usageTab === "sales"
        ? {
          opportunity_id: extractOppIdFromUrl(voucher.opportunityID),
        }
        : {
          type: voucher.other || voucher.usage,
          name: voucher.customerName || null
        }
    }
    if (!!voucher.email) {
      payload["email"] = voucher.email;
    }
    return payload;
  };

  const onSubmitHandle = async (voucher, actions) => {
    if (usageTab === 'others' && !voucher.usage) return;
    actions.setSubmitting(true);
    const payload = getPayload(voucher);

    let data = props.type ? await addPocVoucher(payload) : await addUserVoucher(payload);
    let usageType = !props.type && (user.type === userTypesMap.corporate || user.type === userTypesMap.partner) ? await updateUsage(payload, data.id) : '';
    if (!isEmpty(data) && !data.hasError) {
      setVoucherCodes([
        ...voucherCodes,
        {
          name: payload["name"],
          code: data.code,
        },
      ]);
      actions.resetForm();

      if (props.voucherAdded) {
        props.voucherAdded();
      }
      //@ts-ignore
      const labAssigned = labs.find((val) => val.id === payload.lab_assigned)
        ?.name;
      actions.setFieldTouched("lab");
      actions.setFieldValue("lab", labAssigned);

      if (props.type === 'poc') {
        actions.setFieldValue("name", payload.name);

      }
      actions.setSubmitting(false);
    }
  };


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandle}
    >
      {(formik) => (
        <PureModal
          title={`Generate ${props.type == 'poc' ? 'POC' : ''} Voucher`}
          showModal={true}
          closeModal={() => props.setShowModal(false)}
          primaryBtnLabel="Generate"
          primaryBtnAction={formik.handleSubmit}
          disablePrimaryBtn={!!voucherCodes.length && props.type === 'poc'}
          secondaryBtnLabel="Cancel"
          secondaryBtnAction={() => props.setShowModal(false)}
        >
          <Form>
            <Form.Group>
              <Form.Label>
                Voucher Name
                <RequiredFieldSymbol />
              </Form.Label>
              <Form.Control
                type="text"
                id="name"
                placeholder="Enter name"
                disabled={props.type == 'poc'}
                {...formik.getFieldProps("name")}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                <InputErrorMessage errorMessage={formik.errors.name || ""} />
              </Form.Control.Feedback>
            </Form.Group>


            <Form.Group>
              <Form.Label>
                {`Select ${props.type == 'poc' ? 'Hosted POC Lab' : 'Lab'}`} <RequiredFieldSymbol />
              </Form.Label>
              <select
                className="form-control"
                onChange={(event) => {
                  event.preventDefault();
                  const data = event.target.selectedOptions[0];
                  formik.setFieldTouched("lab");
                  formik.setFieldValue("lab", data.id ? data.value : "");
                  setShowUsageType(data.dataset.customerinforequired === 'true' ? true : false)
                }}
              >
                {labs ? (
                  [<option key="default_option">Select</option>].concat(
                    labs.map((val) => (
                      <option key={val.id} value={val.name} id={val.id} data-customerInfoRequired={val.customerInfoRequired}>
                        {val.name}
                      </option>
                    ))
                  )
                ) : (
                  <option key="no_option">{`No ${props.type == 'poc' ? 'Console VM' : 'Lab'} available`}</option>
                )}
              </select>
              {formik.touched.lab && formik.errors.lab && (
                <InputErrorMessage
                  className={"error-message"}
                  errorMessage={formik.errors.lab || ""}
                />
              )}
            </Form.Group>
            {props.type == 'poc' ?
              ''
              : <>
                {((user.type === userTypesMap.corporate || user.type === userTypesMap.partner) && showUsageType) &&
                  <UsageType usageTab={usageTab} toggleUsageTab={toggleUsageTab} />
                }
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="email"
                    placeholder="Enter Email"
                    {...formik.getFieldProps("email")}
                    isInvalid={!!(formik.touched.email && formik.errors.email)}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <InputErrorMessage
                      className={"error-message"}
                      errorMessage={formik.errors.email || ""}
                    />
                  )}
                </Form.Group>
              </>
            }

            {!formik.errors.email && !!formik.values.email && (
              <Form.Group className="d-flex voucher-row">
                <Form.Label className="mr-4">Send Voucher Email</Form.Label>
                <Form.Check
                  type="switch"
                  id="sendEmail"
                  label=""
                  defaultChecked={formik.values.sendEmail}
                  onChange={(event) => {
                    const isChecked = event.target.checked;
                    formik.setFieldValue("sendEmail", isChecked);
                  }}
                />
              </Form.Group>
            )}
            {!!voucherCodes.length &&
              voucherCodes.map((item, index) => (
                <Form.Group key={`${index}`}>
                  <Form.Label className="voucher-form-label">
                    {item.name}
                  </Form.Label>
                  <div className="d-flex voucher-form-row">
                    <Form.Control
                      disabled
                      type="text"
                      id="voucherCode"
                      readOnly
                      defaultValue={`${window.location.origin}/voucher/${item.code}`}
                    />
                    <CustomTooltip text="Copy Voucher URL">
                      <CopyToClipboard
                        text={`${window.location.origin}/voucher/${item.code}`}
                        onCopy={pushVoucherCopyNotification}
                      >
                        <img
                          src={copy}
                          alt="copy-url"
                          className="cursor-pointer"
                        />
                      </CopyToClipboard>
                    </CustomTooltip>
                  </div>
                </Form.Group>
              ))}
          </Form>
        </PureModal>
      )}
    </Formik>
  );
};

export default UserVoucherModal;
