export const AUTH_LAYOUT_ROUTE = "/auth";
export const USERS_ROUTE = "/user";
export const LABS_ROUTE = `${USERS_ROUTE}/labs`;
export const SUBMIT_TICKET_ROUTE = `${USERS_ROUTE}/submit-ticket`;
export const VOUCHERS_ROUTE = `${USERS_ROUTE}/vouchers`;
export const USER_VOUCHER_ROUTE = "/voucher";
export const STORYLINES_ROUTE = "/storylines";
export const ACCESSDENIED_ROUTE = "/access-denied";
export const USER_STORYLINE_ROUTE = `${USERS_ROUTE}${STORYLINES_ROUTE}`;
export const USER_MODE_STORYLINE_ROUTE = `${USERS_ROUTE}${STORYLINES_ROUTE}/:storylineMode/:storylineId`;
export const VOUCHER_CODE_ROUTE = `${USER_VOUCHER_ROUTE}/:code`;
export const VOUCHER_LOGIN_ROUTE = `${VOUCHER_CODE_ROUTE}/login`;
export const VOUCHER_REGISTER_ROUTE = `${VOUCHER_CODE_ROUTE}/register`;
export const VERIFY_USER_ROUTE = "/verify-user/:uid/:token";
export const SET_USER_PASSWORD_ROUTE = "/set-password";
export const HOME_ROUTE = "/home";
export const GROUPS_ROUTE = "/groups";
export const COMPANIES_ROUTE = "/companies";
export const CONSOLE_ROUTE = `${USERS_ROUTE}/console`;
export const ADMIN_ROUTE = "/admin";
export const ADMIN_USERS_ROUTE = "/users";
export const ADMIN_LABS_ROUTE = "/labs";
export const ADMIN_POCS_ROUTE = "/pocs";
export const ADMIN_STORY_ROUTE = "/story";
export const ADMIN_GROUPS_ROUTE = "/groups";
export const ADMIN_COMPANY_ROUTE = "/company";
export const ADMIN_VOUCHERS_ROUTE = "/vouchers";
export const ADMIN_DASHBOARD_ROUTE = "/dashboard";
export const LOGIN_ROUTE = `${AUTH_LAYOUT_ROUTE}/login`;
export const FORGOT_PASSWORD_ROUTE = `${AUTH_LAYOUT_ROUTE}/forgot-password`;
export const ADMIN_GROUP_USERS_ROUTE = "/groups/:groupId/users";
export const ADMIN_NEW_LAB_ROUTE = "/labs/new";
export const ADMIN_EDIT_LAB_ROUTE = "/labs/edit";
export const ADMIN_NEW_POC_ROUTE = "/pocs/new";
export const ADMIN_EDIT_POC_ROUTE = "/pocs/edit";
export const ADMIN_DETAILS_POC_ROUTE = "/pocs/details";
export const ADMIN_NEW_STORYLINE_ROUTE = "/storylines/new";
export const ADMIN_EDIT_STORYLINE_ROUTE =
  "/storylines/:storylineMode/:storylineId";
export const ADMIN_PRESENTATIONS_ROUTE = "/presentations";
export const NOTIFICATIONS_ROUTE = "/notifications";
export const ADD_NOTIFICATIONS_ROUTE = "/notifications/new";
export const EDIT_NOTIFICATIONS_ROUTE = "/notifications/edit/:notificationId";

export const PRESENTATION_ROUTE = `${USERS_ROUTE}/presentation`;
export const PAGE_NOT_FOUND_ROUTE = "/error/page-not-found";
export const TNC_ROUTE = "/terms-and-condition";
export const OKTA_UNAUTHORIZED_ROUTE = "/error/not-authorised";
export const CHANGE_PASSWORD_ROUTE = `/change-password`;
