import React, { useState, useEffect } from "react"; // Importing React and its hooks.
import "./PocsList.scss"; // Importing custom styles for the Pocs List.
import Table from "src/components/shared/Table"; // Importing a Table component to display the pocss.
import SearchBox from "src/components/shared/SearchBox"; // SearchBox component for filtering pocs.
import NoSearchResultFound from "src/components/ui/NoSearchResultFound"; // Component to show if no search results are found.
import { IPoc } from "src/state/pocs/reducer"; // Importing the Poc interface.
import { RootState } from "src/state/rootReducer"; // Importing the RootState type.
import { useSelector, useDispatch } from "react-redux"; // Hooks to access Redux state and dispatch actions.
import { getPocs } from "src/state/pocs/actions"; // Redux action to fetch pocs.
import { getPocsColumns } from "src/containers/admin/pocs/PocsColumns"; // Column configuration for the table.
// import AccessList from "./AccessList"; // Component to display access details for a poc.
// import { enableLab, deleteLabById } from "src/api/lab"; // API methods for enabling and deleting pocs.
import WarningModal from "src/components/shared/WarningModal"; // Modal to confirm lab deletion.
import { Button, Form } from "react-bootstrap"; // Bootstrap Button component.
import { useHistory } from "react-router-dom"; // Hook to programmatically navigate between routes.
import { ADMIN_ROUTE, ADMIN_NEW_POC_ROUTE, ADMIN_EDIT_POC_ROUTE, ADMIN_DETAILS_POC_ROUTE } from "src/constants/appRoutes"; // Route constants.
import { ReactComponent as AddIcon } from "src/assets/images/add.svg"; // Importing an SVG as a React component.
import Select from 'react-select';
import { IUser } from "src/state/users/reducer";
import { userTypesMap } from "src/constants/users";
import { usersAction } from "src/state/users/actions";
import AddOrEditPoc from "src/components/AddOrEditPoc/AddOrEditPoc";

type PocsListProps = {}; // Defining the props type for the PocsList component.

const PocsList: React.FC<PocsListProps> = (props) => {


  const nonClosedStatuses = [
    'New',
    'Pre-Deployment',
    'Deployment',
    'Active'
  ];

  const closedStatuses = [
    'Canceled',
    'Incomplete',
    'Complete'
  ];

  // State hooks for managing search query, filtered pocs, access list details, modal visibility, etc.
  const [searchBy, setSearchBy] = useState(""); // Search query.
  const [filteredPocs, setFilteredPocs] = useState<IPoc[]>([]); // Filtered list of pocs.
  const [showAddorEditPocModal, setShowAddorEditPocModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPocData, setSelectedPocData] = useState<IPoc>();
  let history = useHistory(); // History hook to navigate routes.
  const dispatch = useDispatch(); // Dispatch hook to dispatch Redux actions.
  const [isPocActive, setIsPocActive] = useState<boolean>()
  const pocs: IPoc[] = useSelector((state: RootState) => state.pocs.list); // Accessing pocs from the Redux store.
  const loaderCount = useSelector((state: RootState) => state.ui.loaderCount); // Checking the loader count to show/hide loading states.
  const user: IUser = useSelector((state: RootState) => state.user)
  const isLoading = loaderCount > 0; // Boolean flag to indicate loading state.
  const [isFiltering, setIsFiltering] = useState(true);
  const corporateUserList: Array<any> = useSelector(
    (state: RootState) => (state.users.list).filter((user) => user.type == userTypesMap.corporate));

  const statusOptions = [
    ...nonClosedStatuses.map((status) => ({ label: status, value: status.toLowerCase() })),
    ...closedStatuses.map((status) => ({ label: status, value: status.toLowerCase() })),
  ];

  useEffect(() => {
    dispatch(getPocs()); // Fetch pocs when the component mounts.
    //  filterPocbyActive()
    if (corporateUserList.length === 0) {
      dispatch(usersAction());
    }
    setIsPocActive(false)
  }, []);


  const toDetailsPoc = (pocId) => {
    history.push(`${ADMIN_ROUTE}${ADMIN_DETAILS_POC_ROUTE}/${pocId}`); // Redirect to edit poc route.
  }
  // Function to the create new poc page.
  const createPoc = () => {
    setIsEditMode(false);
    setShowAddorEditPocModal(true);
  };

  // Function to the create new poc page.
  const handleEditPoc = (pocId) => {
    setIsEditMode(true);
    let selectedPoc = filteredPocs.find((item) => item.pocId == pocId)
    let userData = corporateUserList.find((item) => item.id == selectedPoc?.accountSe);
    //@ts-ignore
    selectedPoc = { ...selectedPoc, se_user: userData }
    setSelectedPocData(selectedPoc)
    setShowAddorEditPocModal(true)
  }

  // Configuring the columns for the table displaying the pocs.
  const pocsColumns = getPocsColumns(
    toDetailsPoc,
    handleEditPoc
  );

  const filterPocList = (searchQuery) => {
    let pocsByStatus = pocs.filter((poc) => !isPocActive ? nonClosedStatuses.includes(poc.status) : true)
    let filteredPocs = pocsByStatus.filter((poc) => {
      if (!searchQuery) return true; // If no search query, return all pocs.
      const pocId = poc.pocId && poc.company.toString().toLowerCase();
      const oppId = poc.oppId && poc.oppId.toString().toLowerCase();
      const query = searchQuery && searchQuery.toLowerCase();
      return (pocId && pocId.includes(query)) || (oppId && oppId.includes(query)); // Match name or tags with the search query.
    });

    setIsFiltering(true); // Set filtering state to true before filtering starts
    setFilteredPocs(filteredPocs); // Update the filtered pocs list.
    setIsFiltering(false); // Set filtering state to false after updating
  };

  // Update filtered pocs whenever the pocs list changes.
  useEffect(() => {
    filterPocList(searchBy); // Filter based on the current search query.
  }, [pocs, searchBy, isPocActive]);

  // Handler to update the search query when the user types in the search box.
  const handleSearchByChange = (updatedSearchQuery) => {
    setSearchBy(updatedSearchQuery); // Update the search query state.
    filterPocList(updatedSearchQuery); // Filter the pocs list based on the new query.
  };

  return (
    <div className="pocs-list-section d-flex flex-column flex-grow-1">
      {showAddorEditPocModal && (
        <AddOrEditPoc
          isEditMode={isEditMode}
          selectedPoc={selectedPocData}
          setShowAddorEditPocModal={setShowAddorEditPocModal}
        />
      )}
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className={`pocs-list-section-header`}>
          <span>
            <b>Hosted POCs</b>
          </span>
        </div>
        {/* <div>
          <div className="multi-select-dropdown">
            {/* Multi-select dropdown for selecting statuses */}
        {/*<label>Filter by POC Status:</label>
            <Select
              options={statusOptions} // Status options for filtering
              isMulti // Enable multi-selection
              value={selectedStatus} // Current selected statuses
              onChange={setSelectedStatus} // Update selected statuses 
              placeholder="Select statuses..."
            />
          </div>
        </div> */}
        <div className="d-flex align-self-center">
          <Form.Check // prettier-ignore
            type="switch"
            id="include_closed"
            label="Include Closed"
            defaultChecked={false}
            checked={isPocActive}
            onClick={(event) => {
              //@ts-ignore
              setIsPocActive(event.target.checked)
              // console.log(event.target.checked)

            }
            }
          />
          <div className="pocs-list-section-search d-flex align-items-center">
            <SearchBox
              updateSearchQuery={handleSearchByChange}
              placeholder="Search by Company or OppId"
            />
          </div>
          {filteredPocs.length ? (
            <Button
              type="submit"
              size="sm"
              className="ml-2"
              variant="primary"
              onClick={createPoc}
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <AddIcon height="10" width="10" />
                <span className="ml-2"> New POC </span>
              </div>
            </Button>
          ) : null}
        </div>
      </div>

      {/* Conditional rendering based on filtering status */}
      {isFiltering ? (
        <div>Loading...</div> // Show loading indicator while filtering
      ) : filteredPocs.length ? (
        <Table searchBy={searchBy} columns={pocsColumns} data={filteredPocs} />
      ) : !!searchBy ? (
        <NoSearchResultFound
          message="Your search was not successful!"
          detail="Try some relevant company or OppId"
          errImgSrc="no-users.svg"
        />
      ) : (
        !isLoading && (
          <>
            <NoSearchResultFound
              message="No Pocs Created!"
              detail="Click below to create a new poc"
              errImgSrc="no-lab.svg"
            />
            <Button
              type="submit"
              size="sm"
              className="ml-2 no-result-add-btn"
              variant="primary"
              onClick={createPoc}
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <AddIcon height="10" width="10" />
                <span className="ml-2"> Create POC </span>
              </div>
            </Button>
          </>
        )
      )}
    </div>
  );
};

export default PocsList;
