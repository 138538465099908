
import {
  toCamelCaseKeys,
  capitalizeFirstLetter,
  formatDateByDefault,
} from "../common";
import { isEmpty } from "src/utils/common";
import { extractOppIdFromUrl } from "../validationsSchema/oppIdValidationSchema";
import { pushNotification } from "src/components/ui/Notification";


export const mapPoc = (poc) => toCamelCaseKeys(poc);
export const mapUserPocs = (pocs = []) => pocs.map(toCamelCaseKeys);
export const mapVoucherPocs = (pocs = []) => pocs.map(toCamelCaseKeys);


export const mapAdminPocs = (pocs = []) =>
  pocs.map(toCamelCaseKeys).map((poc) => {
    const pocCopy = { ...poc };

    pocCopy.assignedUsers = poc.assignedUsers.map((user) => {
      return { ...user.user, expiry: user.expiry };
    });
    pocCopy.assignedGroups = poc.assignedGroups.map((group) => {
      return { ...group.group, expiry: group.expiry };
    });
    pocCopy.vcForUsers = poc.vcForUsers.map((user) => {
      return { ...user.user, expiry: user.expiry };
    });
    pocCopy.vcForGroups = poc.vcForGroups.map((group) => {
      return { ...group.group, expiry: group.expiry };
    });

    return pocCopy;
  });

export const mapAssignedPocs = (pocs) => pocs && pocs.map(toCamelCaseKeys);

export const mapTemplate = (templates) =>
  (templates || [])
    .map(toCamelCaseKeys)
    .filter((template) => !isEmpty(template));


export const mapPOCDetailsbyId = (poc) => {
  return {
    ...poc,
    pocId: poc.poc_id, accountSe: poc.account_se, accountAe: poc.account_ae, podNumber: poc.pod_number,
    oppId: poc.opp_id, createdOn: poc.created_on, requestedStart: poc.requested_start, actualStart: poc.actual_start
  }
}

export const mapPocDetails = (pocDetails) => {
  let mappedPocDetails: any = {
    pocId: pocDetails.pocId,
    status: pocDetails.status,
    deployedBy: pocDetails.deployedBy,
    company: pocDetails.company,
    accountSe: pocDetails.accountSe,
    accountAe: pocDetails.accountAe,
    oppId: pocDetails.oppId,
    labProviderType: "VSA",
    requestedStart: pocDetails.requestedStart,
    actualStart: pocDetails.actualStart,
    endDate: pocDetails.endDate,
    podNumber: pocDetails.podNumber,
    testPlan: pocDetails.testPlan,
    theater: pocDetails.theater,
    accessList: getAccessList(pocDetails),
    voucherConfigList: getVoucherList(pocDetails)
  };

  return mappedPocDetails;
};

const getMappedUsers = (users) =>
  users.map(({ user, expiry }) => {
    return {
      id: user.id,
      fieldType: "user",
      type: capitalizeFirstLetter(user.type),
      name: user.name,
      email: user.email,
      numberOfUsers: 1,
      expiryDate: expiry,
    };
  });

const getMappedGroups = (groups) =>
  groups.map(({ group, expiry }) => {
    return {
      id: group.id,
      fieldType: "group",
      type: "Group",
      name: group.name,
      email: "",
      numberOfUsers: group.userCount,
      expiryDate: expiry,
    };
  });

export const getAccessList = (labDetails) => {
  const userAccessList = labDetails.assignedUsers
    ? getMappedUsers(labDetails.assignedUsers)
    : [];

  const groupAccessList = labDetails.assignedGroups
    ? getMappedGroups(labDetails.assignedGroups)
    : [];

  return [...userAccessList, ...groupAccessList];
};

export const getVoucherList = (labDetails) => {
  const userVoucherList = labDetails.vcForUsers
    ? getMappedUsers(labDetails.vcForUsers)
    : [];

  const groupVoucherList = labDetails.vcForGroups
    ? getMappedGroups(labDetails.vcForGroups)
    : [];
  return [...userVoucherList, ...groupVoucherList];
};

export const getPayload = (poc, isEditMode = false, type?) => {
  poc.requestedStart = convertDateFormat(poc.requestedStart)
  poc.actualStart = poc.actualStart && convertDateFormat(poc.actualStart)
  if (isEditMode) {
    let payload: any;
    // if (!type) {
    payload = {
      company: poc.company,
      opp_id: extractOppIdFromUrl(poc.oppId),
      stage: poc.stage,
      theatre: poc.theatre,
      requested_start: poc.requestedStart,
      account_se: poc.accountSe,
      account_ae: poc.accountAe,
      pod_number: poc.podNumber,
      status: poc.status,
      actual_start: poc.actualStart,
    };
    // }

    return payload;
  }
  let payload: any = {
    status: poc.status,
    deployed_by: poc.deployedBy,
    company: poc.company,
    opp_id: extractOppIdFromUrl(poc.oppId),
    stage: poc.stage,
    theatre: poc.theatre,
    requested_start: poc.requestedStart,
    account_se: poc.accountSe,
    account_ae: poc.accountAe,
    pod_number: poc.podNumber
  };

  function convertDateFormat(date) {
    // Split the input date by '/'
    const [month, day, year] = date.split('/');

    // Reformat the date to 'yyyy-mm-dd'
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }
  let assignedUsers,
    voucherUsers = [] as any;

  assignedUsers = poc.accessList
    .filter((access) => access.fieldType === "user")
    .map((access) => {
      return {
        user: access.id,
        expiry: access.expiryDate
          ? formatDateByDefault(access.expiryDate)
          : null,
      };
    });

  voucherUsers = poc.voucherConfigList
    .filter((voucher) => voucher.fieldType === "user")
    .map((voucher) => {
      return {
        user: voucher.id,
        expiry: voucher.expiryDate
          ? formatDateByDefault(voucher.expiryDate)
          : null,
      };
    });

  payload["assigned_users"] = assignedUsers;
  payload["vc_for_users"] = voucherUsers;

  return payload;
};

export const getResourcePayload = (data, resource, submittedResource) => {
  let totalUsers = 1;
  let prop = "totalUsers";
  if (resource.fieldType !== "user") {
    if (resource.fieldType === "group") {
      prop = "userCount";
    }
    totalUsers = submittedResource[prop]
      ? submittedResource[prop]
      : submittedResource.numberOfUsers;
  }

  const payload = {
    id: submittedResource.id,
    fieldType: resource.fieldType,
    type: capitalizeFirstLetter(resource.fieldType),
    name: submittedResource.name,
    email: submittedResource.email,
    numberOfUsers: totalUsers,
    expiryDate:
      data.expiry === "Custom" ? formatDateByDefault(data.expiryDate) : "",
  };

  return payload;
};

export const launchConsole = (route, history, data, state?) => {
  if (state) {
    history.push(`${route}?id=${data.gid}`, state);
  } else {
    history.push(`${route}?id=${data.gid}`);
  }
};

export const mapPodValues = (settings, pocList, currentPod = 0) => {
  let podValues;
  if (settings) {
    podValues = range(settings.pod_start, settings.pod_end)
  }
  console.log('podValues', podValues)
  if (pocList.length === 0) {
    return podValues;
  }
  const openStates = ['New', 'Pre-Deployment', 'Deployment', 'Active'];
  const activePOCs = pocList.filter(x => openStates.includes(x.status));
  let existingPodValues = new Set(activePOCs.map(item => item.podNumber));
  podValues = podValues.filter(item => !existingPodValues.has(item));

  //For Edit Mode add current pod
  if (currentPod) {
    podValues = insertSorted(podValues, currentPod);
  }
  return podValues;
}

const range = (start, end) => {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}
const insertSorted = (arr, num) => {
  let left = 0;
  let right = arr.length;

  // Binary search to find the correct index
  while (left < right) {
    const mid = Math.floor((left + right) / 2);
    if (arr[mid] < num) {
      left = mid + 1;
    } else {
      right = mid;
    }
  }

  // Insert the number at the correct index
  arr.splice(left, 0, num);
  return arr;
}

export const copyEmailToClipboard = (textObject) => {
  if (navigator.clipboard) {
    // Use the modern Clipboard API
    navigator.clipboard.writeText(textObject.toBeCopied).then(() => {
      pushNotification({ type: "success", message: textObject.successMessage });
    }).catch((err) => {
      pushNotification({ type: "success", message: textObject.errorMessage + err });
    });
  } else {
    // Fallback for older browsers
    const input = document.createElement('input');
    input.value = textObject.toBeCopied;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    pushNotification({ type: "success", message: textObject.successMessage });
  }
};

export const daysBetween = (dateString: string | null): number | string => {
  if (!dateString) {
    return ' - ';
  }
  // Parse the input date string into a Date object
  const inputDate: Date = new Date(dateString);

  // Get the current date
  const currentDate: Date = new Date();

  // Set the time of both dates to midnight to compare only the date part
  inputDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  // Calculate the difference in time (in milliseconds)
  const timeDiff: number = currentDate.getTime() - inputDate.getTime();

  // Convert the time difference from milliseconds to days
  const daysDiff: number = timeDiff / (1000 * 3600 * 24);

  // Return the absolute value of the difference (to handle past or future dates)
  return Math.abs(Math.floor(daysDiff) + 1);
}