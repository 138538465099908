import Api from "src/helpers/request";
import { getAdminLabsEndpoint } from "./lab";

export const getAdminVouchersEndpoint = "/vouchers";
export const getUserVouchersEndpoint = "/user/vouchers";
export const getPocVouchersEndpoint = "/poc-vouchers";
export const postUsageTypeEndpoint = "user/voucher/customer-info";
export const updatePocVouchersEndpoint = "/poc-vouchers";
export const getUserVouchersForAdminEndPoint = "/user-vouchers";
export const verifyVoucherCodeEndPoint = "/user/voucher/verify";
export const activateVoucherCodeEndPoint = "/user/voucher/activate";
export const voucherSignupEndPoint = "/signup";
export const verifyVoucherDetailsEndPoint = "/user/voucher/verify-details";

/* API to fetch labs list */
export const fetchAdminVoucherLabs = (metaConfig = { showLoader: true }) => {
	return Api.call(
		{
			url: `${getAdminVouchersEndpoint}${getAdminLabsEndpoint}?pagination=false`,
			method: "GET",
		},
		metaConfig
	);
};

/* API to fetch admin vouchers list */
export const fetchAdminVouchers = (metaConfig = { showLoader: true }) => {
	return Api.call(
		{
			url: `${getAdminVouchersEndpoint}?pagination=false`,
			method: "GET",
		},
		metaConfig
	);
};

/* API to revoke voucher */
export const revokeAdminVoucher = (data, id) => {
	return Api.call(
		{ url: `${getAdminVouchersEndpoint}/${id}`, method: "put", data },
		{ showLoader: true }
	);
};

/* API to add Voucher details */
export const addAdminVoucher = (data) => {
	return Api.call(
		{ url: `${getAdminVouchersEndpoint}`, method: "post", data },
		{ showLoader: true }
	);
};

/* API to fetch user vouchers list */
export const fetchUserVouchers = (metaConfig = { showLoader: true }) => {
	return Api.call(
		{
			url: `${getUserVouchersEndpoint}?pagination=false`,
			method: "GET",
		},
		metaConfig
	);
};

/* API to add user Voucher details */
export const addUserVoucher = (data) => {
	return Api.call(
		{ url: `${getUserVouchersEndpoint}`, method: "post", data },
		{
			showLoader: true,
			successNotificationMessage: "Voucher added successfully",
		}
	);
};
/* API to add POC Voucher details */
export const addPocVoucher = (data) => {

	return Api.call(
		{ url: `${getPocVouchersEndpoint}`, method: "post", data },
		{
			showLoader: true,
			successNotificationMessage: "Voucher added successfully",
		}
	);
};
export const updateUsage = (data, id) => {
	let { usage_type, usage } = data;
	data = { usage_type, usage, id: id }
	return Api.call(
		{ url: `${postUsageTypeEndpoint}`, method: "post", data },
		{ showLoader: true, }
	);
}

/* API to Update POC Voucher details/ send Email  */
export const updatePocVoucher = (id, data) => {
	return Api.call(
		{ url: `${updatePocVouchersEndpoint}/${id}`, method: "put", data },
		{
			showLoader: true,
			successNotificationMessage: "Email Send successfully",
		}
	);
};

/* API to verify Voucher code */
// export const verifyVoucherCode = (data) => {
//   return Api.call(
//     { url: `${verifyVoucherCodeEndPoint}`, method: "post", data },
//     { showLoader: true, showErrorNotification: false }
//   );
// };
export const verifyVoucherCode = (voucherCode) => {
	return Api.call(
		{ url: `${verifyVoucherCodeEndPoint}/${voucherCode}`, method: "get" },
		{ showLoader: true, showErrorNotification: false, noAuthhHeader: true }
	);
};

export const verifyVoucherDetails = (data) => {
	return Api.call(
		{ url: `${verifyVoucherDetailsEndPoint}`, method: "post", data },
		{ showLoader: true, showErrorNotification: false }
	);
};

/* API to activate Voucher code */
export const activateVoucherCode = (data) => {
	return Api.call(
		{ url: `${activateVoucherCodeEndPoint}`, method: "post", data },
		{ showLoader: true, showErrorNotification: false }
	);
};

/* API to signup to application through Voucher code */
export const voucherSignup = (data) => {
	return Api.call(
		{ url: `${voucherSignupEndPoint}`, method: "post", data },
		{ showLoader: true, showErrorNotification: false }
	);
};

export const fetchUserVouchersForAdmin = () =>
	Api.call(
		{
			url: `${getUserVouchersForAdminEndPoint}?pagination=false`,
			method: "get",
		},
		{ showLoader: true }
	);
