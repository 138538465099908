import React, { useEffect, useState } from "react";
import "./AdminLayout.scss";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { isUserAuthenticated } from "src/utils/common";
import "react-toastify/dist/ReactToastify.css";
import SideBar from "src/components/admin/SideBar";
import { RouteComponentProps } from "react-router";
import AppHeader from "src/components/AppHeader";
import {
  LOGIN_ROUTE,
  ADMIN_USERS_ROUTE,
  ADMIN_COMPANY_ROUTE,
  ADMIN_GROUPS_ROUTE,
  ADMIN_VOUCHERS_ROUTE,
  ADMIN_GROUP_USERS_ROUTE,
  ADMIN_LABS_ROUTE,
  ADMIN_NEW_LAB_ROUTE,
  ADMIN_EDIT_LAB_ROUTE,
  ADMIN_POCS_ROUTE,
  ADMIN_NEW_POC_ROUTE,
  ADMIN_EDIT_POC_ROUTE,
  ADMIN_NEW_STORYLINE_ROUTE,
  ADMIN_EDIT_STORYLINE_ROUTE,
  STORYLINES_ROUTE,
  ADMIN_PRESENTATIONS_ROUTE, ADMIN_DETAILS_POC_ROUTE,
  ADMIN_DASHBOARD_ROUTE, NOTIFICATIONS_ROUTE, ADD_NOTIFICATIONS_ROUTE, EDIT_NOTIFICATIONS_ROUTE,
  ACCESSDENIED_ROUTE,
} from "src/constants/appRoutes";
import Users from "src/containers/admin/Users";
import Companies from "src/containers/admin/Companies";
import Groups from "src/containers/admin/Groups";
import Vouchers from "src/containers/admin/Vouchers";
import GroupUsers from "src/containers/admin/Groups/GroupUser";
import Labs from "src/containers/admin/labs/LabsList";
import Pocs from "src/containers/admin/pocs/PocsList";
import ManageLab from "src/containers/admin/labs/ManageLab";
import ManageStoryLine from "src/containers/admin/StoryLine/ManageStoryLine";
import StoryLineList from "src/containers/admin/StoryLine/StoryLineList";
import Presentations from "src/containers/admin/Presentations";
import Dashboard from "src/containers/Dashboard";
import Notifications from "src/containers/admin/Notifications";
import ManageNotification from "src/containers/admin/Notifications/ManageNotification";
import PocDeatils from "../pocs/PocDetails";
import { getSettings } from "src/state/settings/action";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { getUser } from "src/state/user/actions";


type AdminLayoutProps = {
  showSidebar: boolean;
  exact: boolean;
  path: string;
  header?: React.FC<any>;
  component: React.FC<any>;
} & RouteComponentProps;

const AdminLayout: React.FC<AdminLayoutProps> = (props) => {
  const { pathname, search } = useLocation();
  const loggedUser = useSelector((state: RootState) => (state.user));
  const [isLoading, setIsLoading] = useState(true)
  let redirectUrl = LOGIN_ROUTE;
  if (pathname) {
    redirectUrl = `${LOGIN_ROUTE}?${pathname}${search}`;
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSettings());
  })
  const POCAdminRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          isUserAuthenticated() ? (
            loggedUser.isPOCAdmin ?
              <Component {...props} />
              : <Redirect to={ACCESSDENIED_ROUTE} />
          ) :
            <Redirect to={redirectUrl} />
        }
      />
    );
  };
  useEffect(() => {
    if (loggedUser.isPOCAdmin !== undefined) {
      setIsLoading(false)
    } else {
      if (!isUserAuthenticated()) {
        setIsLoading(false)
      } else {
        dispatch(getUser())
      }
    }
  }, [loggedUser])

  if (isLoading) {
    return <></>
  }
  return isUserAuthenticated() ? (
    <div className="admin-layout">
      <div className="wrapper-panel d-flex">
        <SideBar {...props} />
        <div className="d-flex flex-column w-100">
          <AppHeader />
          <div className="d-flex justify-content-center y-scroll">
            <div className="panel d-flex flex-column w-100">
              <Switch>
                <Route
                  path={`${props.match.url}${ADMIN_DASHBOARD_ROUTE}`}
                  exact
                  component={Dashboard}
                />
                <Route
                  path={`${props.match.url}${ADMIN_USERS_ROUTE}`}
                  component={Users}
                />
                <Route
                  exact
                  path={`${props.match.url}${ADMIN_GROUPS_ROUTE}`}
                  component={Groups}
                />
                <Route
                  path={`${props.match.url}${ADMIN_COMPANY_ROUTE}`}
                  component={Companies}
                />
                <Route
                  path={`${props.match.url}${ADMIN_VOUCHERS_ROUTE}`}
                  component={Vouchers}
                />
                <Route
                  path={`${props.match.url}${ADMIN_GROUP_USERS_ROUTE}`}
                  component={GroupUsers}
                />
                <Route
                  path={`${props.match.url}${ADMIN_LABS_ROUTE}`}
                  exact
                  component={Labs}
                />
                <Route
                  path={`${props.match.url}${ADMIN_NEW_LAB_ROUTE}`}
                  component={ManageLab}
                />
                <Route
                  path={`${props.match.url}${ADMIN_EDIT_LAB_ROUTE}/:labId`}
                  component={ManageLab}
                />

                <POCAdminRoute
                  path={`${props.match.url}${ADMIN_POCS_ROUTE}`}
                  exact
                  component={Pocs}
                />
                <POCAdminRoute
                  path={`${props.match.url}${ADMIN_DETAILS_POC_ROUTE}/:pocId`}
                  component={PocDeatils}
                />
                <Route
                  path={`${props.match.url}${STORYLINES_ROUTE}`}
                  exact
                  component={StoryLineList}
                />
                <Route
                  path={`${props.match.url}${ADMIN_NEW_STORYLINE_ROUTE}`}
                  component={ManageStoryLine}
                  exact
                />
                <Route
                  path={`${props.match.url}${ADMIN_EDIT_STORYLINE_ROUTE}`}
                  component={ManageStoryLine}
                  exact
                />
                <Route
                  path={`${props.match.url}${ADMIN_PRESENTATIONS_ROUTE}`}
                  exact
                  component={Presentations}
                />
                <Route
                  path={`${props.match.url}${NOTIFICATIONS_ROUTE}`}
                  exact
                  component={Notifications}
                />
                <Route
                  path={`${props.match.url}${ADD_NOTIFICATIONS_ROUTE}`}
                  component={ManageNotification}
                />
                <Route
                  path={`${props.match.url}${EDIT_NOTIFICATIONS_ROUTE}`}
                  component={ManageNotification}
                />
                <Redirect to={`${props.match.url}${ADMIN_DASHBOARD_ROUTE}`} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to={redirectUrl} />
  );
};

export default AdminLayout;
